import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'
import { Link } from 'gatsby'

const Nav = (props) => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy items={['home', 'about', 'services', 'contact']} currentClassName="is-active" offset={-300}>
      <li>
        <Scroll type="id" element="home">
          <Link to="/">Home</Link>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="about">
          <Link to="/">About</Link>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="services">
          <Link to="/">Services</Link>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="contact">
          <Link to="/">Contact</Link>
        </Scroll>
      </li>
    </Scrollspy>
  </nav>
)

export default Nav
