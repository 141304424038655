import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'

import coverPhoto from '../assets/images/carien-1.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: true
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>Carien Zeeman Private Practice</title>
          <meta name="description" content="Carien Zeeman, Social Worker in Private Practice" />
          <meta name="keywords" content="carien zeeman,forensic social worker,care and contact evaluations,child assessments,forensic investigations,mediation,supervised visitation,therapy,training" />
        </Helmet>

        <section id="home">
          <div id="header">
            <Header />
          </div>
        </section>

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">

          <section id="about" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>About</h2>
                </header>
                <p>Carien Zeeman is a Social Worker in private practice based in Cape Town. Wanting to understand children and their families in order to enable them to function the best that they can in their specific environment, she obtained her Bachelor’s Degree in Social Work at the University of Stellenbosch.  Thenceforth, she obtained her Honours Degree in Clinical Psychology at the University of Stellenbosch in 2015. In 2020, she completed her Masters Degree in Forensic Practice at the North-West University, Potchefstroom.</p>
                <p>Carien Zeeman is a registered Social Worker since 2014. She acquired experience and honed her skills in working with children and their families at various institutions such as ACVV, Badisa, Wandisa Adoption Agency and Karl Bremer Hospital.</p>
                <p>In addition to Carien’s focus on assessing, supporting and facilitating family and individual functioning, specifically during a context of divorce, she also has a special interest in child and adolescent sexual behaviour and sexual abuse and is accordingly involved in such cases through assessment or mediation.</p>
                <ul className="actions">
                  <li><Link to="/about#info" className="button">Read More</Link></li>
                </ul>
              </div>
              <span className="image"><img src={coverPhoto} alt="" /></span>
            </div>
          </section>

          <section id="services" className="main special">
            <header className="major">
              <h2>Services</h2>
            </header>
            <ul className="features">
              <li>
                <span>
                  <FontAwesomeIcon icon="smile" size="5x" color="#ffb90f78" />
                </span>
                <ul className="actions">
                  <li><Link to="/child-assessments#info" className="button">Child Assessments</Link></li>
                </ul>
              </li>
              <li>
                <span>
                  <FontAwesomeIcon icon="child" size="5x" color="#a89cc8" />
                </span>
                <ul className="actions">
                  <li><Link to="/sexual-abuse-investigations#info" className="button">Sexual Abuse Investigations</Link></li>
                </ul>
              </li>
              <li>
                <span>
                  <FontAwesomeIcon icon="hand-holding-heart" size="5x" color="#77ddd56b" />
                </span>
                <ul className="actions">
                  <li><Link to="/care-contact-evaluations#info" className="button">Care & Contact Evaluations</Link></li>
                </ul>
              </li>
              <li>
                <span className="major style1">
                  <FontAwesomeIcon icon="balance-scale" size="5x" color="#efa8b0" />
                </span>
                <ul className="actions">
                  <li><Link to="/mediation#info" className="button">Mediation</Link></li>
                </ul>
              </li>
              <li>
                <span className="major style1">
                  <FontAwesomeIcon icon="users" size="5x" color="#9bb2e1" />
                </span>
                <ul className="actions">
                  <li><Link to="/supervision#info" className="button">Supervised Visitation</Link></li>
                </ul>
              </li>
            </ul>
            <footer className="major" />
          </section>

          <section id="contact" className="main">
            <header className="major">
              <h2>Contact</h2>
            </header>
            <div className="spotlight">
              <div className="content">
                <dl className="alt">
                  <dt>Address</dt>
                  <dd>
                    <OutboundLink href='https://g.page/carien-zeeman' target='_blank' rel='noreferrer noopener'>
                      Belvedere Office Park &bull; Block A &bull; Pasita Street &bull; Rosenpark &bull; Bellville &bull; 7550
                    </OutboundLink>
                  </dd>
                  <dt>Phone</dt>
                  <dd><OutboundLink href="tel:+27676995159"> 067 699 5159</OutboundLink></dd>
                  <dt>Email</dt>
                  <dd><OutboundLink href="mailto:carien@carienzeeman.co.za">carien@carienzeeman.co.za</OutboundLink></dd>
                </dl>
              </div>
            </div>
          </section>

        </div>

      </Layout>
    )
  }
}

export default Index
